import { getStorage, setStorage } from './storage';

export function setToken(token: string, role: 'SU' | 'DI' | 'IN' | 'PR', storageType: 'localStorage' | 'sessionStorage' = 'localStorage') {
    const tokenLength = token.length;
    const randomPosition = Math.floor(Math.random() * tokenLength);
    const hexNumber = randomPosition.toString(16).padStart(2, '0');

    const result = hexNumber + token.slice(0, randomPosition) + role + token.slice(randomPosition);

    setStorage('token', result, {
        type: storageType
    });
}

function isValidHex(hexString: string) {
    const regex = /^(0x|0X)?[0-9a-fA-F]+$/;

    return regex.test(hexString);
}

export function getToken(storageType: 'localStorage' | 'sessionStorage' = 'localStorage'): string {
    const originToken = getStorage('token', {
        type: storageType
    });

    if (originToken) {
        const positionHex = originToken.slice(0, 2);
        const restToken = originToken.slice(2);
        if (!isValidHex(positionHex)) {
            return '';
        }

        const position = parseInt(originToken.slice(0, 2), 16);
        return restToken.slice(0, position) + restToken.slice(position + 2);
    }

    return '';
}

const roleConvertAuthMap = {
    SU: ['supermanager'],
    DI: ['distributor'],
    IN: ['installer', 'single', 'community', 'office'],
    PR: ['propertyCommunity', 'propertyOldCommunity', 'propertyOffice']
};

/**
 * 检查token中植入的角色是否有目标角色的路由访问权限
 * @param toRole 目标角色
 * @param storageType 存储类型
 * @returns 是否有权限
 */
export function checkToken(toRole: string, storageType: 'localStorage' | 'sessionStorage' = 'localStorage'): boolean {
    const originToken = getStorage('token', {
        type: storageType
    });

    if (!originToken) {
        return false;
    }

    const positionHex = originToken.slice(0, 2);

    if (!isValidHex(positionHex)) {
        return false;
    }

    const rolePosition = parseInt(positionHex, 16);
    const restToken = originToken.slice(2);
    const tokenRole = restToken.slice(rolePosition, rolePosition + 2) as 'SU' | 'DI' | 'IN' | 'PR';

    const tokenRoleConvertAuth = roleConvertAuthMap[tokenRole];

    if (tokenRoleConvertAuth) {
        return tokenRoleConvertAuth.includes(toRole);
    }

    return false;
}
