import { pmCommonApi } from '@/api';
import { openAlarmDialog, ReturnData } from '@/components/view/pm/alarm-dialog/composables/property-alarm-common';
import { clear } from '@/components/view/pm/alarm-dialog/composables/property-alarm-Polling';

/**
 * 高可用设计
 * 1、仅在登录pm帐号后，进行websocket连接。连接时带上验证参数token，token为当前登录的pm的token，例：wss://websocket.akuvox.com?token=xxxxxx
 * 2、ws连接不成功时，进行重试，最多重试3次，前端记录log，且在ws没有成功连接时，每隔10s定时轮询后台获取警告日志接口。
 * 3、ws连接成功后被服务器主动断开（例如token不正确等，尝试重连），前端记录log
 * 4、ws连接成功后并保持连接，进行访问一次后台获取警告日志接口。并每隔10s发送一次ping信息，格式为 {"type":"ping"}
 * 5、主动退出登录时，或者其他tab重新登录其他账号时，断开websocket连接（无须重连）。
 *
 * zyc 2022/09/08
 *
 * 函数名：initProcess
 * 参数：
 * 返回结果
 */

function initProcess(
    wsFn: (wsUrl: string) => void,
    pollFn: Function
) {
    // 初始获取五分钟内的数据
    pmCommonApi.getRecentLog((res: ReturnData) => {
        openAlarmDialog(0, res);
        // 连接websocket
        pmCommonApi.getSysConfig((sysConfig: {websocketHost: string}) => {
            // 请求如果没有wss地址调用轮询
            if (sysConfig.websocketHost) {
                // websocket
                wsFn(sysConfig.websocketHost);
            } else {
            // 轮询
                pollFn();
            }
        });
    }, () => {
        clear();
    });
}

export default initProcess;