import { pmCommonApi } from '@/api';
import { openAlarmDialog, ReturnData } from '@/components/view/pm/alarm-dialog/composables/property-alarm-common';
import eventBus from '@/util/use-event-bus/global-event-bus';
// @ts-ignore
let timer: NodeJS.Timer|null = null;
const pollTime = 3000;
export function initPoll() {
    clear();

    timer = setInterval(() => {
        pmCommonApi.getRecentLog((res: ReturnData) => {
            openAlarmDialog(2, res);
        }, (res: { code: number }) => {
            if (res.code === 2) {
                clear();
            }
        });
    }, pollTime);
    eventBus.once('logOut', clear);
}

export function clear() {
    if (timer) {
        clearInterval(timer);
    }
}
