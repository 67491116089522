import { useEventBus } from '@/util/use-event-bus';
import { EventBusType } from '@/util/use-event-bus/types/eventbus';
import { WebSocketConfig } from './types/websocket';

class WebSocketUtils {
    // WebSocket  实例:用！是因为this.webSocketInstance 赋值 在connect中
    private webSocketInstance!: WebSocket;

    // 最后一次接收ping的时间
    private pingLastTime: number;

    // 检测ping
    // @ts-ignore
    private timesCheckTimeoutInterval: NodeJS.Timeout|null;

    private webSocketConfig: WebSocketConfig;

    // 已重连次数
    private count: number;

    private eventBus: EventBusType;

    // 重连标记
    // @ts-ignore
    private relink: NodeJS.Timeout|null;

    private readonly wsUrl: string;

    // 自己的状态
    private selfState: 'OPEN'|'CLOSE'|'ERROR'|null;

    // 连接用的token
    private linkToken: string;

    constructor(
        url: string,
        configOption: WebSocketConfig
    ) {
        console.log('开始构建ws连接:', url);
        // 获取配置 没有配置使用默认值
        const {
            reconnection = { count: 3, failCallBack: () => ({}) },
            immediate = true,
            autoClose = true
        } = configOption;

        // 创建eventbus线
        this.eventBus = useEventBus();

        this.wsUrl = url;
        this.linkToken = localStorage.getItem('token')!;
        // 保存配置
        this.webSocketConfig = { reconnection, immediate, autoClose };
        // // 心跳检测定时器
        // this.heartCheckTimeoutInterval = null;
        // 已重连次数
        this.count = 1;
        // 重连状态
        this.relink = null;
        this.selfState = null;
        this.pingLastTime = new Date().getTime();
        this.timesCheckTimeoutInterval = null;

        // 默认自动连接
        if (this.webSocketConfig.immediate) {
            this.connect();
        }
    }

    // 连接
    connect() {
        // const protocol = (window.location.protocol === 'https:') ? 'wss://' : 'ws://';
        // const protocol = 'wss://';
        const wsUri = this.wsUrl;
        if (this.webSocketInstance) {
            // 如果有实例先关闭上一个
            this.close();
        }
        this.webSocketInstance = new WebSocket(wsUri);

        // 设置监听回调
        this.init();

        if (this.webSocketConfig.autoClose) {
            window.addEventListener('beforeunload', () => this.close());
        }
    }

    // 设置监听回调函数
    init() {
        console.log('进入init');
        this.webSocketInstance.onmessage = (res) => {
            console.log('message', res);
            // 安装qs可以替代下
            const message = JSON.parse(res.data);
            // socket建立成功后开始进行心跳检测
            if (localStorage.getItem('token') !== this.linkToken) {
                this.close();
                return;
            }
            if (message.type === 'connect') {
                console.log('connect');
                this.count = 1;
                this.selfState = 'OPEN';
                this.pingLastTime = new Date().getTime();
                this.timesCheck();
            }
            if (message.type === 'ping') {
                this.pingLastTime = new Date().getTime();
                this.heartCheck();
            } else {
                this.eventBus.emit(message.type, { ws: this.webSocketInstance, message });
            }
        };
        this.webSocketInstance.onopen = (res) => {
            console.log('open', res);
            this.eventBus.emit('open', { ws: this.webSocketInstance });
        };
        this.webSocketInstance.onerror = (res) => {
            console.log('error', res);
            this.selfState = 'ERROR';
            this.eventBus.emit('error', { ws: this.webSocketInstance });
        };
        this.webSocketInstance.onclose = (res) => {
            console.log('close', res);
            if (this.selfState !== 'CLOSE') {
                this.reconnectionHandler(this.webSocketConfig.reconnection);
            }
            this.eventBus.emit('close', { ws: this.webSocketInstance });
        };
    }

    // 发送socket数据:后续拓展非 1 情况下的队列，在重连后进行重新发送
    send(msg: any) {
        if (this.readyState() === 1) {
            this.webSocketInstance.send(JSON.stringify(msg));
        } else {
            throw Error('websocket sent error');
        }
    }

    // 监听websocket链接状态
    readyState() {
        return this.webSocketInstance.readyState;
    }

    // 心跳检测
    heartCheck() {
        if (this.readyState() === 1) {
            this.send({ type: 'pong' });
        } else {
            this.reconnectionHandler();
        }
    }

    timesCheck() {
        if (this.timesCheckTimeoutInterval) {
            clearInterval(this.timesCheckTimeoutInterval);
        } else {
            this.timesCheckTimeoutInterval = setInterval(() => {
                const now = new Date().getTime();
                if (now - this.pingLastTime > 120000) {
                    this.reconnectionHandler();
                }
            }, 60000);
        }
    }

    // 重连
    reconnectionHandler(reconnection: WebSocketConfig['reconnection'] = {
        count: 3,
        failCallBack: () => ({})
    }) {
        // 重连次数
        const reconnectionCount = reconnection.count;
        if (this.relink) {
            return;
        }
        if (this.count > reconnectionCount) {
            console.log('No more reconnection');
            reconnection.failCallBack();
            this.close();
            return;
        }
        this.relink = setTimeout(() => {
            this.connect();
            console.log('Reconnecting...');
            this.relink = null;
        }, (Math.floor(Math.random() * (30 - 10 + 1)) + 10) * 1000);
        this.count += 1;
    }

    close(code = 1000, reason = '') {
        console.log('instance close');
        clearInterval(this.timesCheckTimeoutInterval!);
        clearTimeout(this.relink!);
        this.timesCheckTimeoutInterval = null;
        this.webSocketInstance.close(code, reason);
        this.selfState = 'CLOSE';
    }

    // 调用eventBus订阅事件
    on(event: string, callbackFn: (data?: any) => void) {
        this.eventBus.on(event, callbackFn);
    }
}

// 初始化websocket连接
function useWebSocket(url: string, { ...option }: WebSocketConfig) {
    console.log('准备创建socket连接');
    console.log('连接地址：', url);
    console.log('配置参数：', { ...option });
    const wsUtils = new WebSocketUtils(url, { ...option });
    return {
        ws: wsUtils
    };
}

// eslint-disable-next-line import/prefer-default-export
export { useWebSocket };
