
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        title: {
            type: String
        }
    },
    setup() {
        const dialogVisible = ref(false);
        return {
            dialogVisible
        };
    }
});
