
import { defineComponent, ref } from 'vue';
import { ElCol, ElMessageBox } from 'element-plus';
import { pmCommonApi } from '@/api';
import NoDevicesNotice from '@/views/pm/alarm-records/components/no-devices-notice.vue';
import actionResultDialog from './action-result-dialog.vue';

export default defineComponent({
    expose: ['dialogVisible'],
    components: {
        ElCol,
        actionResultDialog,
        NoDevicesNotice
    },
    setup() {
        const dialogVisible = ref(false);
        const actionResultDialogRef = ref();
        const noDevicesNoticeRef = ref();
        const logUUID = ref('');
        const lockType = ref(0);
        const noticeTitle = ref('');
        // 开关锁操作 type 0:关门 1:开门
        function lockOp(type: 0|1) {
            lockType.value = type;
            ElMessageBox.confirm(
                type === 1 ? WordList.sureUnlockAllDoors : WordList.sureLockAllDoors,
                WordList.TabelDeleteTitle,
                {

                    confirmButtonText: WordList.TabelFootSure,
                    cancelButtonText: WordList.TabelFootCancel,
                    type: 'warning'
                }
            )
                .then(() => {
                    pmCommonApi.emergencyAction({ Type: type }, [(res: {
                        LogUUID: string;
                    }) => {
                        if (res.LogUUID === '') {
                            noticeTitle.value = type === 0 ? WordList.LockAll : WordList.UnlockAll;
                            dialogVisible.value = false;
                            noDevicesNoticeRef.value.dialogVisible = true;
                        } else {
                            logUUID.value = res.LogUUID;
                            dialogVisible.value = false;
                            actionResultDialogRef.value.dialogVisible = true;
                            actionResultDialogRef.value.title = type === 1 ? WordList.UnlockAll : WordList.LockAll;
                        }
                    }, false]);
                });
        }
        return {
            dialogVisible,
            lockOp,
            actionResultDialogRef,
            logUUID,
            lockType,
            noDevicesNoticeRef,
            noticeTitle
        };
    }
});
