
import { defineComponent } from 'vue';

export default defineComponent({
    setup(props, { emit }) {
        const account = localStorage.getItem('userName') as string;

        const onClose = () => {
            emit('close');
            window.location.reload();
        };

        return {
            account,
            onClose
        };
    }
});
