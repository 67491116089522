/**
 * 全局弹窗数据 弹窗与uuid做绑定 不考虑刷新前的数据
 */
import { dialog } from '@/util/dialog-render';

// 展示的弹窗
let showAlarmDialogUUID = new Set();
// 关闭过的弹窗
let closeAlarmDialogUUID = new Set();

// // 总共警告次数
// let alarmCountTotal = 0;
// // 当前剩余的警告弹窗次数
// let alarmCountCurDialog = 0;

interface ReturnData{
    MAC: string;
    Location: string;
    ProjectName: string;
    CreateTime: string;
    Status: string;
    UUID: string;
}

function openHandler(data: ReturnData) {
    showAlarmDialogUUID.add(data.UUID);
    // alarmCountTotal += 1;
    // alarmCountCurDialog += 1;
    dialog(
        'components/view/pm/alarm-dialog/components/property-alarm-dialog',
        {
            props: {
                alarmInfo: {
                    ...data
                },
                alarmCountTotal: showAlarmDialogUUID.size,
                onVanish: () => {
                    // alarmCountCurDialog -= 1;
                    closeAlarmDialogUUID.add(data.UUID);
                    showAlarmDialogUUID.delete(data.UUID);
                }
            }
        }
    );
}
/**
 * 功能：打开弹窗事件
 * 函数名：openAlarmDialog
 * 参数：
 * type: 0 默认请求 ｜ 1 websocket请求 ｜ 2 轮询请求
 * data: 返回的数据
 */
function openAlarmDialog(
    type: 0|1|2,
    data: ReturnData|ReturnData[]
) {
    // 一开始的默认请求情况
    if (type === 0) {
        showAlarmDialogUUID = new Set();
        closeAlarmDialogUUID = new Set();
    }
    if (data instanceof Array) {
        if (data.length === 0) {
            return;
        }
        data.forEach((item) => {
            // 如果不存在
            if (!showAlarmDialogUUID.has(item.UUID) && !closeAlarmDialogUUID.has(item.UUID)) {
                openHandler(item);
            }
        });
    } else if (!showAlarmDialogUUID.has(data.UUID) && !closeAlarmDialogUUID.has(data.UUID)) {
        openHandler(data);
    }
}

// 关闭弹窗事件

export {
    openAlarmDialog,
    ReturnData
};
