// 后期迁移移到layout/composables目录下
import { useWebSocket } from '@/util/use-websocket';
import { EventBusType } from '@/util/use-event-bus/types/eventbus';
import globalEventBus from '@/util/use-event-bus/global-event-bus';
import { openAlarmDialog } from '@/components/view/pm/alarm-dialog/composables/property-alarm-common';
import { initPoll } from '@/components/view/pm/alarm-dialog/composables/property-alarm-Polling';

export default function initWebSocket(
    eventBus: EventBusType = globalEventBus,
    wsUrl = ''
) {
    // 接收 websocket 消息
    interface Msg{
        type: string;
        message: any;
    }

    // 初始化websocket
    const token = localStorage.getItem('token');
    const { ws } = useWebSocket(`${wsUrl}?token=${token}`,
        {
            reconnection: {
                count: 3,
                failCallBack: onExceedReconnectionCount
            }
        });

    ws.on('msgAlarm', (msg: Msg) => {
        openAlarmDialog(1, msg.message.data);
    });

    // websocket重连失败后回调
    function onExceedReconnectionCount() {
        initPoll();
    }

    // 监听退出操作
    eventBus.once('logOut', () => {
        ws.close();
    });
}
