
import { linkPmAccount } from '@/api/pm/account';
import { defineComponent, ref, Ref } from 'vue';

interface FormData {
    Account: string;
    Passwd: string;
}

export default defineComponent({
    setup(props, { emit }) {
        const formRef = ref();
        const linkCountdown = ref(0);

        const formData: Ref<FormData> = ref({
            Account: '',
            Passwd: ''
        });
        const rules = {
            Account: [
                {
                    required: true,
                    message: WordList.UsernameNotEmpty,
                    trigger: 'change'
                }
            ],
            Passwd: [
                {
                    required: true,
                    message: WordList.PasswordNotEmpty,
                    trigger: 'change'
                }
            ]
        };

        const submit = () => {
            formRef.value.validate((valid: boolean) => {
                if (valid) {
                    linkPmAccount({
                        Account: formData.value.Account,
                        Passwd: hex_md5(formData.value.Passwd)
                    }, [() => {
                        emit('success');
                        emit('close');
                    }, false], (res: any) => {
                        if (res.data.Time) {
                            linkCountdown.value = parseInt(res.data.Time, 10);

                            const timer = setInterval(() => {
                                linkCountdown.value -= 1;
                                if (linkCountdown.value === 0) {
                                    clearInterval(timer);
                                }
                            }, 1000);
                        }
                    });
                }
            });
        };

        return {
            formRef,
            linkCountdown,
            formData,
            rules,
            submit
        };
    }
});
